import { template as template_feb7e5050427477ba29e9cef62ed7ffa } from "@ember/template-compiler";
const FKControlMenuContainer = template_feb7e5050427477ba29e9cef62ed7ffa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
