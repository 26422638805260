import { template as template_9cfe695f5ec74bbc9f41b2b8a1647a80 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_9cfe695f5ec74bbc9f41b2b8a1647a80(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
