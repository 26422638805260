import { template as template_26d32a34399d4752822d71c4e0f2b033 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_26d32a34399d4752822d71c4e0f2b033(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
