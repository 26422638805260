import { template as template_65edbbac0e3b4c569cd72426a65394de } from "@ember/template-compiler";
const WelcomeHeader = template_65edbbac0e3b4c569cd72426a65394de(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
