import { template as template_ceb03c141cbb471baec292fb3f7ac986 } from "@ember/template-compiler";
const FKText = template_ceb03c141cbb471baec292fb3f7ac986(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
